import styled from 'styled-components';
import {Modal, ModalBody} from "react-bootstrap";

const StyledModal = styled(Modal)`
.modal-dialog {
  width: 300px;
  transform: translate(0, 40vh) translate(0, -50%) !important;
  .modal-header {
    padding: 0px 10px;
    border-bottom: none;
  }
  .close {
    font-size: 38px;
    font-weight: 500
  }
  .modal-body{
    width: 100%;
  }
`;
const StyledBody = styled(ModalBody)`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-flow: column;
`;

const ImageContainer = styled.div`
width: 102px;
height: 102px;
margin-bottom: 15px;
> img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
`;
const NameContainer = styled.div`
width: 100%;
color: #3A3A3A;
text-align: center;
font-size: 24px;
line-height: 24px;
font-family: 'Rubik-Medium';
margin-bottom: 15px;
`;
const DepartmentContainer = styled.div`
color: #999999;
mix-blend-mode: normal;
opacity: 0.5;
font-family: 'Rubik-Regular';
font-size: 18px;
margin-bottom: 10px;

`;
const LocationContainer = styled.div`
color: #9EA0A5;
font-size: 14px;
line-height: 16px;
font-family: 'Rubik-Regular';
text-align: center;
`;
const WellnessInterests = styled.div`
width: 100%;
display: flex;
padding: 0 20px;
justify-content: space-evenly;
margin-top: 10px;
margin-bottom: 10px;
> div {
  width: 32px;
  height: 32px;
  background-color: #F6F6F8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  > img {
    width: 20px;
    height: 20px;
  }
}
`;
const AddBuddyContainer = styled.div`
width: 100%;
height: 40px;
text-align: center;
background: #FD7175;
border-radius: 3px;
margin-bottom: 15px;
> button {
    width: 200px;
    height: 100%;
    background: transparent;
    border-radius: 3px;
    border: none;
    outline: none;
    font-size: 20px;
    line-height: 20px;
    font-family: 'Rubik-Regular';
    color: #FFF;
}

.addBuddy {
    background-color: #FD7175;
    outline: none;
    border: navajowhite;
    width: 100%;
    height: 40px;
    padding: 2px;
    border-radius: 3px;
	cursor: pointer;
	display: flex;
    justify-content: center;
    align-items: center;
	>span {
		color: #FFF;
		font-size: 13px;
		font-family: 'Rubik-Regular';
		line-height: 2px;
	}
  }

  .pending{
	background-color:#f6b479;
	outline: none;
    border: navajowhite;
    width: 100%;
    height: 100%;
    padding: 2px;
    border-radius: 3px;
	cursor: pointer;
	display: flex;
    justify-content: center;
    align-items: center;
	>span{
		color: #FFF;
		font-size: 16px;
		font-family: 'Rubik-Regular';
		line-height: 2px;
	}
  }
`;
const CancelContainer = styled.div`
width: 100%;
height: 40px;
border-radius: 3px;

> button {
  width: 100%;
  height: 100%;
  color: #A7ABB1;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Rubik-Regular';
  border: 1px solid #A7ABB1;
  box-sizing: border-box;
  border-radius: 3px;
  background: transparent;
  outline: none;
}
`;
const ParaContainer = styled.div`
color: #BDBDBD;
font-size: 18px;
line-height: 24px;
text-align: center;
font-family: 'Rubik-Regular';
mix-blend-mode: normal;
opacity: 0.5;
margin-bottom: 15px;
> span {
  color: #3A3A3A;
  font-family: 'Rubik-Medium';
}
`;
const RemoveBuddyContainer = styled.div`
  width: 100%;
  height: 50px;
  border-top: 1px solid #F1F1F1;
  border: ${({border}) => border && '1px solid #D8D8D8'};
  border-radius: ${({border}) => border && '4px'};
  padding: ${({border}) => border && '8px !important'};
`;
const ButtonRemove = styled.button`
border-radius: 3px;
color: #FD7175;
font-size: 18px;
line-height: 24px;
text-align: center;
font-family: 'Rubik-Medium';
border: 1px solid #F1F1F1;
width: 100%;
height: 100%;
background: transparent;
outline: none;
border: none;
`;
const CancelButton = styled.button`
border-radius: 3px;
width: 100%;
height: 100%;
color: #BDBDBD;
font-size: 18px;
line-height: 24px;
text-align: center;
font-family: 'Rubik-Regular';
border: 1px solid #F1F1F1;
background: transparent;
outline: none;
border: none;
`;

const RectBox = styled.div`
  width: 100%;
  width: 100%;
  min-height: 50px;
  padding: 15px 15px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  background: rgba(156,156,156,0.1);
  >div{
    >textarea{
      border: none !important;
      outline: none !important;
      // background: rgb(255 255 255 / 0%);
      background: transparent;
    }
  }
`;

const UploadImage = styled.label`
  width: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 4px;
  padding: 11px 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  >img{
    width: 33px;
    height: 30px;
    margin-right: 18px;
  }
  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;

const CloseIcon = styled.div`
  width: 100%;
  text-align: right;
  height: 10%;
  svg {
   cursor: pointer;
  }
  img{
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`
const CustomInputTextArea = styled.div`
  position: relative;
  height: 100%;
  width: ${({width}) => width};
  padding: ${({padding}) => padding};
  >textarea{
    width: 100%;
    display: flex;
    resize: none;
    border: ${({border}) => border};
    padding-top: ${({border}) => border && '2px'};  
    height: ${({border}) => border && '20px'};
    font-size: 16px;
    color: #005c87;
    overflow:hidden;
    &:focus, &:active, &:hover, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    &::placeholder {
      opacity: 1;
      color: #005C8799;
    }

    &:-ms-input-placeholder {
      color: #005C8799;
    }

    &::-ms-input-placeholder {
      color: #005C8799;
    }
  }
`;
export {
  StyledModal, ImageContainer, StyledBody, NameContainer, DepartmentContainer, LocationContainer, WellnessInterests,
  AddBuddyContainer, CancelContainer, ParaContainer, RemoveBuddyContainer, ButtonRemove, CancelButton, RectBox, UploadImage, CloseIcon, CustomInputTextArea
}
