/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal, CalendarHeader, CalendarWrapper, ConfirmVisitText, ButtonContainer, ButtonV2
} from '../../PeopleHomeV2/ManageBuddies/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unFriendUser, getSuggestionsList} from '../../../redux/actions';

class RemoveFriendPopup extends Component {
  constructor(props) {
    super(props);
  }

  removeBuddy = (ID) => {
    const { unFriendUser, onClose, socialCallBack, getSuggestionsList} = this.props;
    if (ID) {
      unFriendUser(ID, false);
    }
    socialCallBack();
    getSuggestionsList();
    onClose(); 
  }

  render() {
    const { showModal, onClose, buddyDetails } = this.props;
    const userId= buddyDetails && buddyDetails.uid;
    return (
      <StyledModal show={showModal ? true : false} width={"400px"}>
        <CalendarHeader>
          <div>{"Notification"}</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {
            <ConfirmVisitText>
              Are you sure you want to remove <br/>{buddyDetails.fname+' '+buddyDetails.lname} from your buddy list?
            </ConfirmVisitText>
          }  
          <ButtonContainer>
            <ButtonV2
              background="#005c87"
              onClick={() => this.removeBuddy(userId)}
              width="100%"
              margin="0 0 10px 0"
              backgroundHover="#005C87"
              colorHover="white"
              color="white"
              border="1"
            >
              {"Yes, Remove"}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#005C8799"
              onClick={() => onClose()}
              width="100%"
              backgroundHover="#005C87"
              colorHover="white"
            >
              {"Cancel"}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  }
}

RemoveFriendPopup.propTypes = {
  showModal: PropTypes.string,
  onClose: PropTypes.func,
  deleteSocialInspiration: PropTypes.func,
  feed: PropTypes.any,
  updationList: PropTypes.string,
  deleteUser: PropTypes.func,
  t: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  getSuggestionsList: PropTypes.func,
  socialCallBack: PropTypes.func,
  removeBuddy: PropTypes.func,
  showpending: PropTypes.bool,
  showAddBuddy: PropTypes.bool,
  buddyDetails: PropTypes.array,
  unFriendUser: PropTypes.func
};

const mapStateToProps = (state) => ({
  sendRequest: state.social.sendRequest,
  friendRequests: state.social.friendRequests,
});

const mapDispatchToProps = (dispatch) => ({
  unFriendUser: (ID, bool) => dispatch(unFriendUser(ID, bool)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RemoveFriendPopup));