/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Img} from "react-image";
import { StyledModal, ImageContainer, StyledBody, NameContainer, DepartmentContainer, LocationContainer,
  WellnessInterests, AddBuddyContainer, CancelContainer, ParaContainer, RemoveBuddyContainer, ButtonRemove,
  CancelButton, CloseIcon
} from './styles';
import { ImageUrl } from '../../../utils/constants';
import { isUndefined } from 'lodash';
import { sendFriendRequest, getSuggestionsList, removeBuddy} from '../../../redux/actions';
import { withTranslation } from 'react-i18next';

class AddBuddyPopUp extends React.Component {
  constructor() {
    super();
  }
  addBuddy = (Id) => {
    const { sendFriendRequest, onClose, socialCallBack, getSuggestionsList} = this.props;
    sendFriendRequest(Id, false);
    getSuggestionsList();
    socialCallBack();
    onClose();
  }
  rejectBuddy = (Id) => {
    const { onClose, socialCallBack, removeBuddy, getSuggestionsList} = this.props;
    removeBuddy(Id);
    getSuggestionsList();
    socialCallBack();
    onClose();
  }
  render() {
    const { showModal, onClose, buddyDetails, showRemoveBuddyButton, hideCancel, pendingStatus, friendStatus, t} = this.props;
    return(
      <StyledModal
        show={showModal}
        onHide={() => onClose()}
      >
        {!showRemoveBuddyButton ?
          buddyDetails ? buddyDetails.map((user, index) => (
            <StyledBody key={index}>
              <CloseIcon onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect width="24" height="24" rx="3" fill="#EAEAEA"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.8942 7.631C16.4291 7.16592 15.6751 7.16592 15.21 7.631L12.2622 10.5788L9.3154 7.63198C8.85032 7.1669 8.09628 7.1669 7.6312 7.63198C7.16612 8.09706 7.16612 8.8511 7.6312 9.31618L10.578 12.263L7.63108 15.2099C7.166 15.675 7.16601 16.429 7.63108 16.8941C8.09616 17.3592 8.8502 17.3592 9.31528 16.8941L12.2622 13.9472L15.2101 16.8951C15.6752 17.3602 16.4292 17.3602 16.8943 16.8951C17.3594 16.43 17.3594 15.676 16.8943 15.2109L13.9464 12.263L16.8942 9.3152C17.3593 8.85012 17.3593 8.09608 16.8942 7.631Z" fill="#9C9C9C"/>
                </svg>
              </CloseIcon>
              <ImageContainer>
                <Img
                  alt={user && !isUndefined(user.fname) && user.fname && user.lname  ? user.fname+' '+buddyDetails.lname : null}
                  src={user ? `${ImageUrl}/${user.profile_image}` : '/public/images/neutral_avatar.svg'}
                  unloader={
                    <Img
                      src='/public/images/neutral_avatar.svg'
                      alt='avatar'
                    />
                  }
                />
              </ImageContainer>
              { user && !isUndefined(user.fname) ?
                <NameContainer>
                  {user.fname} {user.lname}
                </NameContainer>
                : null
              }
              {user && user.department ?
                <DepartmentContainer>
                  {user.department}
                </DepartmentContainer>
                : null
              }
              {user && user.city && user.country ?
                <LocationContainer>
                  {user.state}
                </LocationContainer>
                : null
              }
              <WellnessInterests>
                {user && user.interests.length > 0 ?
                  user.interests.slice(0,5).map((data, index) => (
                    <div key={index}>
                      <img src={`${ImageUrl}/${data.interest_image}`} />
                    </div>
                  ))
                  : null
                }
              </WellnessInterests>
              <AddBuddyContainer>
                {
                  friendStatus === "Pending" || user.request_status === "Pending" || pendingStatus ? <div className="pending" ><span>{t("Pending")}</span></div> : <div className="addBuddy" onClick={() => this.addBuddy(user.uid)}><span>{t("Add Buddy")}</span></div>
                }
              </AddBuddyContainer>
              { friendStatus === "Pending" ||  hideCancel || pendingStatus ? <CancelContainer>
                <button onClick={() => this.rejectBuddy(user.uid)}>{t("Cancel")}</button>
              </CancelContainer>: null}
            </StyledBody>
          ))
            : null
          :
          <StyledBody>
            <ImageContainer>
              <Img
                alt="Name"
                src="/public/images/wellness-expert-image.png"
                unloader={
                  <Img
                    src='/public/images/neutral_avatar.svg'
                    alt='avatar'
                  />
                }
              />
            </ImageContainer>
            <ParaContainer>
              {`If you change your mind, you'll have to add `}<span>{`@ Roxiel Mills`}</span> again.
            </ParaContainer>
            <RemoveBuddyContainer>
              <ButtonRemove>
                {t("Remove Buddy")}
              </ButtonRemove>
            </RemoveBuddyContainer>
            <RemoveBuddyContainer>
              <CancelButton onClick={() => onClose()}>
                {t("Cancel")}
              </CancelButton>
            </RemoveBuddyContainer>
          </StyledBody>
        }
      </StyledModal>
    );
  }
}
AddBuddyPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  buddyDetails: PropTypes.array,
  sendFriendRequest: PropTypes.func,
  showRemoveBuddyButton: PropTypes.bool,
  hideCancel: PropTypes.number,
  socialCallBack: PropTypes.func,
  getSuggestionsList: PropTypes.func,
  pendingStatus: PropTypes.bool,
  removeBuddy: PropTypes.func,
  friendRequests: PropTypes.array,
  friendStatus: PropTypes.string,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  sendRequest: state.social.sendRequest,
  friendRequests: state.social.friendRequests,
});

const mapDispatchToProps = (dispatch) => ({
  sendFriendRequest: (data, bool) => dispatch(sendFriendRequest(data, bool)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  removeBuddy: (acceptData) => dispatch(removeBuddy(acceptData)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddBuddyPopUp));
