import styled from "styled-components";

const EmojiPickerContainer = styled.div`
z-index: 99;
float: left;
float:${({comment})=>comment && "right"};
position: relative;
display: flex;
align-items: center;
flex-wrap: wrap;
height: 100%;
outline: none;
border: none;
color: #000000;
font-size: 18px;
line-height: 2px;
font-family: 'Rubik';
background: #FFFFFF;
box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.18);
border-radius: 0px 6px 6px 6px;
padding: 10px;
left: 33px;
left:${({comment})=>comment && "0"};
width:${({comment})=>comment && "100%"};
// left: ${({left}) => left ? '365px' : '33px'};
// top: ${({top}) => top ? '-277px' : '0px'}
>div{
    position: absolute;
    top: -5px;
    right: 0px;
    background: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
}
.emoji-mart{
   width: ${({comment})=>comment && "100% !important"}
}
`;

const EmojiMain = styled.div`
position: ${({show})=>show ? "absolute":"absolute"};
top: ${({show})=>show ? "":"100%"};
right: ${({show})=>show ? "":"0px"};
`;

const EmojiPickerContainerNew= styled.div`
position:relative;

em-emoji-picker {
    width:100% !important;
}
`

export { EmojiPickerContainer, EmojiMain, EmojiPickerContainerNew};
