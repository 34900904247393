/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
// import 'emoji-mart/css/emoji-mart.css';
// import Picker  from 'emoji-mart';
// import data from '@emoji-mart/data'
import data from '@emoji-mart/data/sets/14/twitter.json'
import Picker from '@emoji-mart/react'
// import { EmojiPickerContainer } from './styles';
import {EmojiPickerContainerNew} from './styles';

class SocialFeedsEmojiPicker extends React.Component {
  selectedEmoji = (emoji) => {
    const { selectEmoji } = this.props;
    selectEmoji(emoji, emoji.id, emoji.native, emoji.skin, emoji.unified);
  };

  render() {
    const { /*comment,*/ hidePicker} = this.props;
    return (
    // <div ref={this.props.emojiRef} style={{ position: 'absolute', top: isCommentModal ? '160%' : '100%', right: isCommentModal ? "50%" : '0', }}>
    //   <EmojiPickerContainer comment={comment}>
    //     <div onClick={hidePicker} style={{position:"relative", top:"-235px", left:"190px"}}>x</div>
    //     <Picker
    //       title='Pick your emoji…'
    //       emoji='point_up'
    //       set='facebook'
    //       onEmojiSelect={(emoji) => this.selectedEmoji(emoji)}
            
      //       // data={data}
      //     />
      //   </EmojiPickerContainer>
      // </div>
      <div ref={this.props.emojiRef} style={{display:"flex", width:"100%"}}>
        <EmojiPickerContainerNew style={{width:"100%"}}>
          <Picker
            data={data}
            title='Pick your emoji…'
            emoji='point_up'
            // set='facebook'
            // onEmojiSelect={(emoji) => {window.console.log("emoji from picker: ", emoji); this.selectedEmoji(emoji)}}
            onEmojiSelect={this.selectedEmoji}
            style={{width:"535px"}}
            dynamicWidth={true}
          />
          <div onClick={hidePicker} style={{position:"absolute", top:"-28px", right:/* "0" */ "0px",zIndex:"10",cursor:"pointer", fontSize:"24px" }}>x</div>
        </EmojiPickerContainerNew>
      </div>
    );
  }
}

SocialFeedsEmojiPicker.propTypes = {
  selectEmoji: PropTypes.func.isRequired,
  emojiRef: PropTypes.object.isRequired,
  comment: PropTypes.bool.isRequired,
  hidePicker: PropTypes.func.isRequired,
  isCommentModal:PropTypes.bool
};

export default SocialFeedsEmojiPicker;
